import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className='tier1-1 ques'>
    <span>2</span>
    <span>(a)</span>
    <span>(i)</span>
    <span>Insert text here</span>
    </div>
    <div className='tier1-2 ques'>
    <span>2</span>
    <span>(a)</span>
    <span>Insert text here</span>
    </div>
    <div className='tier1-3 ques'>
    <span>2</span>
    <span>Insert text here</span>
    </div>
    <div className='tier2 ques'>
    <span>(a)</span>
    <span>Insert text here</span>
    </div>
    <div className='tier2-1 ques'>
    <span>(a)</span>
    <span>(i)</span>
    <span>Insert text here</span>
    </div>
    <div className='tier3 ques'>
    <span>(i)</span>
    <span>Insert text here</span>
    </div>
    <div className='tier4 ques'>
    <span>a)</span>
    <span>Insert text here</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      